@media screen and (max-width: $screen-size) {
  .mobile-content-title {
    padding: (12 / $tsc) + rem (16 / $tsc) + rem 0 (16 / $tsc) + rem !important;
    height: (88 / $tsc) + rem !important;
    background-image: url('./images/step/step-bg.png') !important;
    background-size: cover !important;
    background-repeat: no-repeat !important;
    background-position-y: bottom !important;
    justify-content: normal !important;
    & > div > p:first-child {
      line-height: unset !important;
      font-size: (16 / $tsc) + rem !important;
      font-family: 'OpenSans_Bold' !important;
    }
    p:last-child {
      line-height: unset !important;
      font-size: (12 / $tsc) + rem !important;
      font-family: 'OpenSans_Regular' !important;
      margin: 0 !important;
    }
  }
  .mobile-page {
    padding: (25 / $tsc) + rem (16 / $tsc) + rem (24 / $tsc) + rem (16 / $tsc) +
      rem !important;
    border-radius: (16 / $tsc) + rem;
  }
  .mobile-step-wrap {
    padding: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .mobile-step-header {
    display: flex;
    align-items: center;
  }
  .mobile-step-line {
    content: 'span';
    width: (30 / $tsc) + rem;
    height: (2 / $tsc) + rem;
    background-color: rgb(214, 216, 218);
    border-radius: (48 / $tsc) + rem;
    &:last-child {
      display: none;
    }
    &.is-completed {
      background: #d8edde;
      fill: #3da758;
    }
    &.is-incomplete {
      background: #fcf1c8;
    }
  }
  .mobile-step-box {
    display: flex;
    align-items: center;
    cursor: pointer;
    .is-active {
      background-color: #1f78ad;
    }
    .is-completed {
      background: #d8edde;
      fill: #3da758;
    }
    .is-incomplete {
      background: #fcf1c8;
    }
  }
  .mobile-step {
    height: (32 / $tsc) + rem;
    width: (32 / $tsc) + rem;
    border-radius: 50%;
    background: #d6d8da;
    color: #fff;
    font-size: (16 / $tsc) + rem;
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .is-disabled {
    cursor: unset !important;
  }
  .mobile-sidebar-horizontal {
    flex: unset !important;
    max-width: unset !important;
    min-width: unset !important;
    width: unset !important;
    background: unset !important;
    border-right: unset !important;
    padding: unset !important;
    height: unset !important;
    overflow-y: unset !important;
    position: unset !important;
  }
}
